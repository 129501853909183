import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Row, Col, Input, Tooltip } from 'reactstrap';
import { InputAnalyte } from './InputAnalyte';
import { InputImagenology } from './InputImagenology';
import { InputsMicro } from './InputsMicro';
import './styleCaptureResults.css';
import { PresumptiveResult } from './PresumptiveResult';
import { ComponentCommentGlobal } from './ComponentCommentGlobal';
import { PresumptiveSave } from './PresumptiveSave';


export const ViewExam = ({

    // ! ANALYTE
    typeArray, sectionProfileId, examen, age, days_age, gender, saveIndividualAnalyte, releaseExam, validateAnalyte, validateExam, analytes, idPat, namePatiente, nameP, surnameP, surnameM, modal, toggle, curves = null,
    microBacteriums, addMicroAislado, microAntibiograms, previewWorkOrder, saveFileExamImagenology, handleGetDocumentImagenology,
    removeValidationFromExamOrProfile,updateMethod,setAnalytes,is_profile,

    // ? PRESUMTIVE RESULTS
    addPresumptiveResult,presumptive,setPresumptive,removePresumptiveResult,
    handleChangePresumptive,PrintingPresumptiveResult,cancelRemovePresumtiveResults,printingGlobal,cancelPrintingPresumptive,removePrintingGlobal,
    valdatePresumtiveResults,ReleasePresumtiveResults,unvalidatePresumtiveResults,unreleasePresumtiveResults,

    // ? BACTERIUM
    setMicroBacteriums,handleTypeBacterium,handleChangeComments,
    removeBacterium1,removeBacteriumAntibitic,ValidateBacterium,UnValidateBacterium,ReleaseBacterium,UnReleaseBacterium,microAntibiotics,
    setValidMicro


}) => {

    

    const getBranchProcess = () => {
        try {
            let json = localStorage.getItem("list_branch_storage");
            var branches = JSON.parse(json);
            var idx_branch = branches.findIndex(b => b.id_branch == parseInt(id_branch));
            if(idx_branch == -1) return [];
            else return branches[idx_branch].ids_branch_process;
        } catch (error) {
            return [];
        }
    }

   let _valid = examen.is_micro ? true : false
   setValidMicro(_valid)

    console.log("🚀 ~ IndexCaptureResults1:", examen)
    console.log("🚀 ~ IndexCaptureResults2:", microBacteriums)



    
 
    
    let id_branch = localStorage.getItem('branchId');
    let ids_branch_process = getBranchProcess() || [];
    let enable_interface_imagenology = localStorage.getItem('enable_interface_imagenology');
    enable_interface_imagenology = (enable_interface_imagenology == "true" ? true : false);

    const [examMethod, setExamMethod] = useState(0);
    const [appCodeOrMethodExam, setAppCodeOrMethodExam] = useState(0);
    const [readyToRelease, setReadyToRelease] = useState(false);
    const [riguroso, setRiguroso] = useState(false);
    const [releaseData, setReleaseData] = useState(null);
    const [readyToValidate, setReadyToValidate] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);



    const changeMethod = ({ target }) => {
        const selectedMethodId = target.value; // El ID del método seleccionado
    
        // Buscar el método seleccionado en la lista de métodos del examen
        const selectedMethod = examen.methods.find(x => x.id_exam_method == selectedMethodId);
    
        if (selectedMethod) { // Verificar si se encontró un método válido
            setExamMethod(selectedMethod.id_method);
            setAppCodeOrMethodExam(selectedMethod.id_exam_method);
    
            // Crear una copia modificada de la lista de 'analytes'
            const updatedAnalytes = analytes.map(item => {
                if (item.id_exam === examen.id_exam) {
                    return {
                        ...item,
                        id_app_code_or_method_exam: selectedMethod.id_exam_method
                    };
                }
                return item;
            });
    
           
            setAnalytes(updatedAnalytes);
        }
    };
    
    
    const canReport = (id_branch_wo) => {
        if(parseInt(id_branch) == id_branch_wo) return true;
        else {
            var idx = ids_branch_process.findIndex(id => id == id_branch_wo);
            return (idx >= 0);
        }
    }

    useEffect(() => {
       
        let method = examen.methods.find(x => x.id_exam_method == examen.method_selected);
        if(method!=undefined){
            if (examen.method_selected !== null) {
                setExamMethod(method.id_method);
                setAppCodeOrMethodExam(examen.method_selected);
            } else {
                if (examen.methods.length > 0) {
    
                    if (examen.id_app_code_method_default !== null && examen.id_app_code_method_default !== undefined) {
    
                        let examen_default = examen.methods.find(x => x.id_exam_method === examen.id_app_code_method_default);
                        if (examen_default !== undefined && examen_default !== null) {
                            setExamMethod(examen_default.id_method);
                            setAppCodeOrMethodExam(examen_default.id_exam_method);
                        } else {
                            setExamMethod(examen.methods[0].id_method);
                            setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                        }
                    } else {
                        setExamMethod(examen.methods[0].id_method);
                        setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                    }
    
                }
            }
        }else{
            if (examen.methods.length > 0) {
        
                        if (examen.id_app_code_method_default !== null && examen.id_app_code_method_default !== undefined) {
        
                            let examen_default = examen.methods.find(x => x.id_exam_method === examen.id_app_code_method_default);
                            if (examen_default !== undefined && examen_default !== null) {
                                setExamMethod(examen_default.id_method);
                                setAppCodeOrMethodExam(examen_default.id_exam_method);
                            } else {
                                setExamMethod(examen.methods[0].id_method);
                                setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                            }
                        } else {
                            setExamMethod(examen.methods[0].id_method);
                            setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                        }
        
                    }
               
        }

       

        let validacion = true;
        let riguroso_validate=true;

        if (!enable_interface_imagenology && examen.is_imagenology) {
            if (examen.imagenology != null) {
                if (examen.imagenology.required) {
                    if (examen.imagenology.isCaptured === false) {
                        validacion = false;
                    }
                }
            }
            setReadyToRelease(validacion);
        }
        else if (!enable_interface_imagenology && !examen.is_imagenology) {
            if(examen.analytes !== null && examen.analytes.length > 0){
                if(examen.analytes.filter(a => a.required).length == 0){
                    validacion = false;
                    riguroso_validate=true;
                }

                examen.analytes.forEach(element => {
                    if (element.required) {
                        riguroso_validate=false;
                        if (element.isCaptured === false) {
                            validacion = false;
                            riguroso_validate=true;
                        }else{
                            if(validacion==false){
                                riguroso_validate=true;
                            }else{
                                riguroso_validate=false;
                            }

                        }
                    }else{
                        riguroso_validate=false; 


                    }

                });
            }
            setRiguroso(riguroso_validate);
            setReadyToRelease(validacion);
        }

        if (examen.isRelease) {
            let { user } = examen;
            let obj = {
                "date": examen.release_date,
                "user": `${user.name} ${user.paternal_surname} ${user.maternal_surname} / ${user.username}`
            }
            setReleaseData(obj);
        }

    }, [])

    useEffect(() => {
        let validacion = true;
        let validacionExam = true;
        let riguroso_validate=true;
        // debugger;
        if (!enable_interface_imagenology && examen.is_imagenology) {
            if (examen.imagenology !== null) {
                if (examen.imagenology.required) {
                    if (examen.imagenology.isCaptured === false) {
                        validacion = false;
                        validacionExam = false;
                    }
                    if (examen.imagenology.userValidate === null) {
                        validacion = false;
                    }
                }
                
                setReadyToRelease(validacion);
                setReadyToValidate(validacionExam);
            }
        }
        else if (!enable_interface_imagenology && !examen.is_imagenology) {
            if(examen.analytes !== null && examen.analytes.length > 0){
                if(examen.analytes.filter(a => a.required).length == 0){
                    validacion = false;
                    validacionExam = false;
                    riguroso_validate=true;
                }

                examen.analytes.forEach(element => {
                    if (element.required) {
                        riguroso_validate=false;
                        if (element.isCaptured === false) {
                            validacion = false;
                            validacionExam = false;
                            riguroso_validate=true;
                        }else{
                            riguroso_validate=false;
                            if(validacion==true){
                                riguroso_validate=false;
                            }else{
                                riguroso_validate=true;
                            }
                        }
                        if (element.userValidate === null) {
                            validacion = false;
                        }
                    
                    }else{

                        riguroso_validate=false;

                    }
                });
            } 
           
            setRiguroso(riguroso_validate);
            setReadyToRelease(validacion);
            setReadyToValidate(validacionExam);
        }
        else if (enable_interface_imagenology && !examen.is_imagenology) {
            examen.analytes !== null && examen.analytes.forEach(element => {
                if (element.required) {
                    riguroso_validate=false;
                    if (element.isCaptured === false) {
                        validacion = false;
                        validacionExam = false;
                        riguroso_validate=true;
                    }else{
                        riguroso_validate=false;
                        if(validacion==true){
                            riguroso_validate=false;
                        }else{
                            riguroso_validate=true;
                        }
                    }
                    if (element.userValidate === null) {
                        validacion = false;
                       
                    }
                    
                }else{
                    
                        riguroso_validate=false;
                   
                }
                
            });
           
           
            setRiguroso(riguroso_validate);
            setReadyToRelease(validacion);
            setReadyToValidate(validacionExam);
        }

        if (examen.isRelease) {
            let { user } = examen;
            let obj = {
                "date": examen.release_date,
                "user": `${user.name} ${user.paternal_surname} ${user.maternal_surname} / ${user.username}`
            }
            setReleaseData(obj);
        } else {
            setReleaseData(null)
        }

    }, [examen.imagenology, examen.analytes, examen.isRelease])



    return (
        <Row>
            <Col sm="12">
                <Card className="shadow">
                    <CardBody className="p-3 pb-0">
                        <Row>
                            <Col sm="4" className='' >
                                <h6>{examen.name} </h6>
                            </Col>
                            <Col sm="3">
                                {
                                    (enable_interface_imagenology && examen.is_imagenology)
                                        ?
                                        examen.release_date === null ? "En espera de resultados" : "Resultados liberados"
                                        :
                                        (!enable_interface_imagenology && !examen.is_imagenology)
                                            ?
                                            (canReport(examen.id_branch))
                                                ?
                                                <Row>
                                                    <Col sm="11">
                                                        <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.id_estatus_exam != 1 && examen.id_estatus_exam != 2 && examen.id_estatus_exam != 3 ? true : false} value={appCodeOrMethodExam}>
                                                        {
                                                            examen.methods.map((metodo, key3) => {
                                                                return <option style={{backgroundColor: metodo.is_active !== true ? '#e9ecef' : 'inherit' }} key={key3} value={metodo.id_exam_method} disabled={metodo.is_active==true?false:true}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                            })
                                                        }
                                                        </Input>
                                                    </Col>
                                                    <Col sm="1">
                                                   
                                                        <i onClick={ examen.release_date != null ? () => alert(examen.id_work_order_exam) : () => updateMethod(examen.id_work_order_exam)} className={examen.release_date!=null ? "disabled fa fa-refresh f-28 pointer text-primary" : "fa fa-refresh f-28 pointer text-primary"}></i>
                                                    </Col>
                                                </Row>
                                                
                                                :
                                                examen.is_internal_maquila
                                                    ?
                                                    examen.isRelease
                                                        ?
                                                        <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.id_estatus_exam != 1 && examen.id_estatus_exam != 2 && examen.id_estatus_exam != 3 ? true : false} value={appCodeOrMethodExam}>
                                                            {
                                                                examen.methods.map((metodo, key3) => {
                                                                    return <option style={{backgroundColor: metodo.is_active !== true ? '#e9ecef' : 'inherit' }} key={key3} value={metodo.id_exam_method} disabled={metodo.is_active==true?false:true}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        : <p>El examen esta siendo procesado en {examen.name_branch}</p>
                                                    : <p>No tienes acceso para capturar el resultado</p>
                                            : ((enable_interface_imagenology && !examen.is_imagenology)) ?
                                                (canReport(examen.id_branch))
                                                    ?
                                                    <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.id_estatus_exam != 1 && examen.id_estatus_exam != 2 && examen.id_estatus_exam != 3 ? true : false} value={appCodeOrMethodExam}>
                                                        {
                                                            examen.methods.map((metodo, key3) => {
                                                                return <option style={{backgroundColor: metodo.is_active !== true ? '#e9ecef' : 'inherit' }} key={key3} value={metodo.id_exam_method} disabled={metodo.is_active==true?false:true}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    :
                                                    examen.is_internal_maquila
                                                        ?
                                                        examen.isRelease
                                                            ?
                                                            <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.id_estatus_exam != 1 && examen.id_estatus_exam != 2 && examen.id_estatus_exam != 3 ? true : false} value={appCodeOrMethodExam}>
                                                                {
                                                                    examen.methods.map((metodo, key3) => {
                                                                        return <option style={{backgroundColor: metodo.is_active !== true ? '#e9ecef' : 'inherit' }} key={key3} value={metodo.id_exam_method} disabled={metodo.is_active==true?false:true}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            : <p>El examen esta siendo procesado en {examen.name_branch}</p>
                                                        : <p>No tienes acceso para capturar el resultado</p>
                                                : ""
                                }
                            </Col>
                            <Col sm="5" className='text-right'>
                                {
                                    (!enable_interface_imagenology && (!examen.is_imagenology))
                                        ?
                                        (canReport(examen.id_branch))
                                            ?
                                            <ButtonGroup tabIndex="-1">
                                                {
                                                    releaseData === null
                                                        ?
                                                        !readyToRelease && (
                                                            <Button id='tooltipValidate' tabIndex="-1" className={!readyToValidate ? "d-none" : ""} outline color='success' onClick={readyToValidate ? () => validateExam(examen.id_work_order_exam, examen.id_exam, typeArray, sectionProfileId) : null}>Validar prueba</Button>
                                                        )
                                                        : ""
                                                }
                                                {
                                                    readyToValidate
                                                        ? ""
                                                        :
                                                        <Tooltip
                                                            isOpen={tooltipOpen}
                                                            target="tooltipValidate"
                                                            toggle={toggleTooltip}
                                                        >
                                                            Guarde todos los resultados en rojo para poder validar toda la prueba
                                                        </Tooltip>
                                                }
                                                {
                                                    releaseData === null
                                                        ?
                                                        (readyToRelease && <>
                                                            <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => removeValidationFromExamOrProfile(examen.id_work_order_exam)} color='danger' outline>Desvalidar</Button>
                                                            <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => releaseExam(examen.id_work_order_exam, examen.id_exam)} color='secondary'>Liberar prueba</Button>
                                                        </>)
                                                        :
                                                        <>
                                                            <Button tabIndex="-1"  onClick={() => removeValidationFromExamOrProfile(examen.id_work_order_exam)} color='danger' outline>Desvalidar</Button>
                                                            <span style={{ display: "flex", alignItems: "center" }} className='badge badge-light-primary'><h6 className='p-0 m-0'>PRUEBA LIBERADA</h6></span>
                                                        </>
                                                }
                                            </ButtonGroup>
                                            :
                                            (releaseData === null)
                                                ?
                                                !readyToRelease ? <p>En espera de validación</p> : <p>En espera de liberación</p>
                                                : <h5>PRUEBA LIBERADA</h5>
                                        : (enable_interface_imagenology && (!examen.is_imagenology)) ?
                                            (canReport(examen.id_branch))
                                                ?
                                                <ButtonGroup tabIndex="-1">
                                                    {
                                                        releaseData === null
                                                            ?
                                                            !readyToRelease && (
                                                                <Button id='tooltipValidate' tabIndex="-1" className={!readyToValidate ? "d-none" : ""} outline color='success' onClick={readyToValidate ? () => validateExam(examen.id_work_order_exam, examen.id_exam, typeArray, sectionProfileId) : null}>Validar prueba</Button>
                                                            )
                                                            : ""
                                                    }
                                                    {
                                                        readyToValidate
                                                            ? ""
                                                            :
                                                            <Tooltip
                                                                isOpen={tooltipOpen}
                                                                target="tooltipValidate"
                                                                toggle={toggleTooltip}
                                                            >
                                                                Guarde todos los resultados en rojo para poder validar toda la prueba
                                                            </Tooltip>
                                                    }
                                                    {
                                                        releaseData === null
                                                            ?
                                                            (readyToRelease && <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => releaseExam(examen.id_work_order_exam, examen.id_exam)} color='secondary'>Liberar prueba</Button>)
                                                            : <h5>PRUEBA LIBERADA</h5>
                                                    }
                                                </ButtonGroup>
                                                :
                                                (releaseData === null)
                                                    ?
                                                    !readyToRelease ? <p>En espera de validación</p> : <p>En espera de liberación</p>
                                                    : <h5>PRUEBA LIBERADA</h5>
                                            : ""
                                }
                            </Col>
                        </Row>
                        {
                            !enable_interface_imagenology && examen.is_imagenology &&
                            <InputImagenology
                                dataExam={examen}
                                imagenologyData={examen.imagenology}
                                releaseData={releaseData}
                                saveFileExamImagenology={saveFileExamImagenology}
                                sectionProfileId={sectionProfileId}
                                handleGetDocumentImagenology={handleGetDocumentImagenology}
                            />
                        }
                        {
                            canReport(examen.id_branch) && examMethod > 0 &&
                            ((curves === null || curves === undefined) ?
                                <>
                                    {
                                        examen.analytes.map((analyte, key4) => {
                                            return <InputAnalyte
                                                analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                                    {
                                                        "id": "",
                                                        "capture": "",
                                                        "id_exam": "",
                                                        "id_type_analyte": "",
                                                        "id_exam_method": "",
                                                        "decimals": "",
                                                        "compute": "",
                                                        "variable": "",
                                                        "comment": "",
                                                        "id_analyte_references": ""
                                                    }
                                                }
                                                changeMethod={changeMethod}
                                                dataExam={examen}
                                                validateAnalyte={validateAnalyte}
                                                releaseData={releaseData}
                                                saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={age} days_age={days_age} gender={gender} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                                allCurves={"Esta entrando por aca"}
                                                previewWorkOrder={previewWorkOrder}
                                            />
                                        })
                                    }
                                    <hr />
                                    {
                                        examen.is_micro && //!CAPTURE RESULTS
                                        <>
                                        {
                                        //*PRESUNTIVE PROFILE
                                        }
                                        <Row className={examen.presumptive_results?.length > 0 ? "d-none" : 'border-top pt-2 mt-2' }    >
                                            <Col sm="12">
                                            <h5>Resultados Preliminar</h5>
                                            <button className='btn btn-warning' tabIndex="-1"  onClick={() => addPresumptiveResult(examen.id_work_order_exam, is_profile === true ?"profile":"section",examen.id_exam,sectionProfileId)}>Nuevo Presuntivo</button>         
                                            </Col>
                                        </Row>
                                        <div style={{backgroundColor:"#51bb2536"}}>
                                        {
                                        examen.presumptive_results !== undefined && examen.presumptive_results !== null ?
                                            <>
                                                {
                                                    examen.presumptive_results?.length > 0 && examen.presumptive_results.map((_presumtive, _key) => (
                                                        <>
                                                            <PresumptiveSave 
                                                            idExam={examen.id_work_order_exam}
                                                            presumptive={presumptive}
                                                            handleChangePresumptive={handleChangePresumptive}
                                                            presumptive2={presumptive}
                                                            printingGlobal={printingGlobal}
                                                            removePrintingGlobal={removePrintingGlobal}
                                                            typeArray={typeArray}
                                                            sectionProfileId={sectionProfileId}
                                                            examId={examen.id_exam}
                                                            />
                                                            {
                                                                _presumtive?.presumptives?.length > 0 && (
                                                                    <PresumptiveResult
                                                                        key={_presumtive?.presumptives[0]?.id_presumptive_result}
                                                                        addPresumptiveResult={addPresumptiveResult}
                                                                        setPresumptive={setPresumptive}
                                                                        removePresumptiveResult={removePresumptiveResult}
                                                                        idExam={examen.id_work_order_exam}
                                                                        position={_key}
                                                                        presumptive={presumptive}
                                                                        savePresuntive={_presumtive?.close_capture}
                                                                        PrintingPresumptiveResult={PrintingPresumptiveResult}
                                                                        cancelRemovePresumtiveResults={cancelRemovePresumtiveResults}
                                                                        cancelPrintingPresumptive={cancelPrintingPresumptive}
                                                                        valdatePresumtiveResults={valdatePresumtiveResults}
                                                                        ReleasePresumtiveResults={ReleasePresumtiveResults}
                                                                        unvalidatePresumtiveResults={unvalidatePresumtiveResults}
                                                                        unreleasePresumtiveResults={unreleasePresumtiveResults}
                                                                        typeArray={typeArray}
                                                                        sectionProfileId={sectionProfileId}
                                                                        examId={examen.id_exam}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                            : null
                                    }
                                        </div>
                                        <hr />
                                            <Row >
                                                <Col sm="12">
                                                    {
                                                        examen.isRelease === false ?
                                                            <button className='btn btn-success' tabIndex="-1" onClick={() => addMicroAislado(examen.id_work_order_exam, is_profile==true ?"profile":"section")}>Nuevo microorganismo</button>
                                                            : ""
                                                    }

                                                </Col>
                                            </Row>
                                            <div style={{ paddingBottom: 80 }}>
                                                {
                                                    examen.bacteriums !== undefined && examen.bacteriums !== null && examen.bacteriums.length > 0 ?
                                                        <>
                                                            {
                                                                microBacteriums.map((_bacter, _keys) => {                                                                
                                                                    return <InputsMicro
                                                                            key={_bacter.id_mic_bacterium}
                                                                            idExam={examen.id_work_order_exam}
                                                                            examId={examen.id_exam}
                                                                            position={_keys}
                                                                            is_profile={is_profile}
                                                                            sectionProfileId={sectionProfileId}
                                                                            listMicroBacteriums={microBacteriums} 
                                                                            microBacteriums={_bacter} 
                                                                            setMicroBacteriums={setMicroBacteriums}
                                                                            handleTypeBacterium={handleTypeBacterium}
                                                                            microAntibiograms={microAntibiograms}
                                                                            handleChangeComments={handleChangeComments}
                                                                            removeBacterium1={removeBacterium1}
                                                                            removeBacteriumAntibitic={removeBacteriumAntibitic}
                                                                            ValidateBacterium={ValidateBacterium}
                                                                            typeArray={typeArray}
                                                                            UnValidateBacterium={UnValidateBacterium}
                                                                            ReleaseBacterium={ReleaseBacterium}
                                                                            UnReleaseBacterium={UnReleaseBacterium}
                                                                    />
                                                                })
                                                            }
                                                        </>
                                                        : ""
                                                }
                                            </div>
                                            {/* ComponentCommentGlobal */}
                                        </>
                                    }
                                    <hr />
                                </>
                                :
                                examen.analytes.map((analyte, key4) => {
                                    return <InputAnalyte
                                        analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                            {
                                                "id": "",
                                                "sub_id": curves.find(x => x.time.value === analyte.sub_id).time.value,
                                                "capture": "",
                                                "id_exam": "",
                                                "id_type_analyte": "",
                                                "id_exam_method": "",
                                                "decimals": "",
                                                "compute": "",
                                                "variable": "",
                                                "comment": "",
                                                "id_analyte_references": ""
                                            }
                                        }
                                        changeMethod={changeMethod}
                                        dataExam={examen}
                                        validateAnalyte={validateAnalyte}
                                        releaseData={releaseData}
                                        saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={age} days_age={days_age} gender={gender} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                        dataCurve={curves.find(x => x.time.value === analyte.sub_id)}
                                    />
                                })
                            )
                        }
                        {
                            !canReport(examen.id_branch) && examen.isRelease &&
                            ((curves === null || curves === undefined) ?
                                <>
                                    {
                                        examen.analytes.map((analyte, key4) => {
                                            return <InputAnalyte
                                                analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                                    {
                                                        "id": "",
                                                        "capture": "",
                                                        "id_exam": "",
                                                        "id_type_analyte": "",
                                                        "id_exam_method": "",
                                                        "decimals": "",
                                                        "compute": "",
                                                        "variable": "",
                                                        "comment": "",
                                                        "id_analyte_references": ""
                                                    }
                                                }
                                                changeMethod={changeMethod}
                                                dataExam={examen}
                                                validateAnalyte={validateAnalyte}
                                                releaseData={releaseData}
                                                saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={age} days_age={days_age} gender={gender} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                                allCurves={"Esta entrando por aca"}
                                            />
                                        })
                                    }
                                    {
                                        examen.is_micro &&
                                        <>
                                        {
                                        //*PRESUNTIVE SECTIONS
                                        }
                                        <Row className={examen.presumptive_results?.length > 0 ? "d-none" : 'border-top pt-2 mt-2' }    >
                                            <Col sm="12">
                                            <h5>Resultado Preliminar</h5>
                                            <button className='btn btn-warning' tabIndex="-1"  onClick={() => addPresumptiveResult(examen.id_work_order_exam, "section",examen.id_exam,sectionProfileId,)}>Nuevo Presuntivo</button>         
                                            </Col>
                                        </Row>
                                        <div style={{backgroundColor:"#51bb2536"}} >
                                        {
                                        examen.presumptive_results !== undefined && examen.presumptive_results !== null ?
                                            <>
                                                {
                                                    examen.presumptive_results?.length > 0 && examen.presumptive_results.map((_presumtive, _key) => (
                                                        <>
                                                            <PresumptiveSave 
                                                            idExam={examen.id_work_order_exam}
                                                            presumptive={presumptive}
                                                            handleChangePresumptive={handleChangePresumptive}
                                                            presumptive2={presumptive}
                                                            printingGlobal={printingGlobal}
                                                            removePrintingGlobal={removePrintingGlobal}
                                                            typeArray={typeArray}
                                                            sectionProfileId={sectionProfileId}
                                                            examId={examen.id_exam}
                                                            />
                                                            {
                                                                _presumtive?.presumptives?.length > 0 && (
                                                                    <PresumptiveResult
                                                                        key={_presumtive?.presumptives[0]?.id_presumptive_result}
                                                                        addPresumptiveResult={addPresumptiveResult}
                                                                        setPresumptive={setPresumptive}
                                                                        removePresumptiveResult={removePresumptiveResult}
                                                                        idExam={examen.id_work_order_exam}
                                                                        position={_key}
                                                                        presumptive={presumptive}
                                                                        savePresuntive={_presumtive?.close_capture}
                                                                        PrintingPresumptiveResult={PrintingPresumptiveResult}
                                                                        cancelRemovePresumtiveResults={cancelRemovePresumtiveResults}
                                                                        cancelPrintingPresumptive={cancelPrintingPresumptive}
                                                                        typeArray={typeArray}
                                                                        sectionProfileId={sectionProfileId}
                                                                        examId={examen.id_exam}
                                                                        antibitic={examen}
                                                                        />
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>
                                            : null
                                    }
                                          

                                        </div>
                                        <hr />
                                            <Row className="border-top pt-2 mt-2">
                                                <Col sm="12">
                                                    <button className='btn btn-success' tabIndex="-1" onClick={() => addMicroAislado(examen.id_work_order_exam, "section",examen.id_exam,sectionProfileId)}>Nuevo microorganismo</button>
                                                </Col>
                                            </Row>
                                            <div style={{ paddingBottom: 80 }}>
                                                {
                                                    examen.bacteriums !== undefined && examen.bacteriums !== null && examen.bacteriums.length > 0 ?
                                                        <>
                                                            {
                                                                microBacteriums.map((_bacter, _keys) => {                                                                
                                                                    return <InputsMicro
                                                                            key={_bacter.id_mic_bacterium}
                                                                            idExam={examen.id_work_order_exam}
                                                                            examId={examen.id_exam}
                                                                            position={_keys}
                                                                            is_profile={is_profile}
                                                                            sectionProfileId={sectionProfileId}
                                                                            listMicroBacteriums={microBacteriums} 
                                                                            microBacteriums={_bacter} 
                                                                            setMicroBacteriums={setMicroBacteriums}
                                                                            handleTypeBacterium={handleTypeBacterium}
                                                                            microAntibiograms={microAntibiograms}
                                                                            handleChangeComments={handleChangeComments}
                                                                            removeBacterium1={removeBacterium1}
                                                                            removeBacteriumAntibitic={removeBacteriumAntibitic}
                                                                            ValidateBacterium={ValidateBacterium}
                                                                            typeArray={typeArray}
                                                                            UnValidateBacterium={UnValidateBacterium}
                                                                            ReleaseBacterium={ReleaseBacterium}
                                                                            UnReleaseBacterium={UnReleaseBacterium}
                                                                    />
                                                                })
                                                            }
                                                        </>
                                                        : ""
                                                }
                                            </div>
                                       
                                        </>
                                    }
                                </>
                                :
                                examen.analytes.map((analyte, key4) => {
                                    return <InputAnalyte
                                        analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                            {
                                                "id": "",
                                                "sub_id": curves.find(x => x.time.value === analyte.sub_id).time.value,
                                                "capture": "",
                                                "id_exam": "",
                                                "id_type_analyte": "",
                                                "id_exam_method": "",
                                                "decimals": "",
                                                "compute": "",
                                                "variable": "",
                                                "comment": "",
                                                "id_analyte_references": ""
                                            }
                                        }
                                        changeMethod={changeMethod}
                                        dataExam={examen}
                                        validateAnalyte={validateAnalyte}
                                        releaseData={releaseData}
                                        saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={age} days_age={days_age} gender={gender} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                        dataCurve={curves.find(x => x.time.value === analyte.sub_id)}
                                    />
                                })
                            )
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row >
    )
}
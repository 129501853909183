import React, { useEffect, useState } from 'react'
import {Col, Input } from 'reactstrap'

export const ComponentCommentGlobal = ({ handleChangeIsolated,microComments }) => {


    return (
        <>
          <h5>Comentario Global</h5>
            <Col sm="12" className='p-l-0 p-r-0'>
                <Input
                    type='text'
                    className='form-control form-control-sm input-air-primary border-primary input-mic'
                    value={microComments} 
                    onChange={(e) => handleChangeIsolated(e)}
                /> 
            </Col>      
        </>
    )
}
